import gql from 'graphql-tag';

import { UserPrivilegeFragment } from '@confluence/current-user/entry-points/UserPrivilege.fragment';

export const AutomationAdminQuery = gql`
	query AutomationAdminQuery($spaceKey: String!) {
		...UserPrivilegeFragment
		space(key: $spaceKey) {
			id
			currentUser {
				isAdmin
			}
		}
	}

	${UserPrivilegeFragment}
`;
