import type { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';

import { getIsProductOrSiteAdminFromQuery } from '@confluence/current-user';

import type {
	AutomationAdminQuery as AutomationAdminQueryType,
	AutomationAdminQueryVariables,
} from './__types__/AutomationAdminQuery';
import { AutomationAdminQuery } from './AutomationAdminQuery.graphql';

export type AutomationPermissionValue = {
	canManageAutomations: boolean;
	error?: ApolloError;
};

export const useAutomationPermissionService = (spaceKey: string): AutomationPermissionValue => {
	const skipQuery = !spaceKey || Boolean(process.env.REACT_SSR); // AutomationAdminQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests
	const { data, error } = useQuery<AutomationAdminQueryType, AutomationAdminQueryVariables>(
		AutomationAdminQuery,
		{
			variables: {
				spaceKey: spaceKey!,
			},
			skip: skipQuery,
		},
	);

	const isSpaceAdmin = data?.space?.currentUser?.isAdmin ?? false;
	const isProductOrSiteAdmin = getIsProductOrSiteAdminFromQuery(data);

	return {
		canManageAutomations: isSpaceAdmin || isProductOrSiteAdmin,
		error,
	};
};
