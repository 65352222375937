import type { UserPrivilegeFragment } from './__types__/UserPrivilegeFragment';
import { getUserPrivilegeFromQuery } from './getUserPrivilegeFromQuery';
import { UserPrivilege } from './UserPrivilege';

export function getIsProductOrSiteAdminFromQuery(
	queryData: UserPrivilegeFragment | undefined,
): boolean {
	const userPrivilege = getUserPrivilegeFromQuery(queryData);

	return (
		userPrivilege === UserPrivilege.PRODUCT_ADMIN || userPrivilege === UserPrivilege.SITE_ADMIN
	);
}
