import type { UserPrivilegeFragment } from './__types__/UserPrivilegeFragment';
import { getIsProductAdminFromQuery } from './getIsProductAdminFromQuery';
import { getIsSiteAdminFromQuery } from './getIsSiteAdminFromQuery';
import { UserPrivilege } from './UserPrivilege';

export function getUserPrivilegeFromQuery(
	queryData: UserPrivilegeFragment | undefined,
): UserPrivilege | undefined {
	const isSiteAdmin = getIsSiteAdminFromQuery(queryData);
	const isProductAdmin = getIsProductAdminFromQuery(queryData);

	return typeof queryData === 'undefined'
		? undefined
		: isSiteAdmin
			? UserPrivilege.SITE_ADMIN
			: isProductAdmin
				? UserPrivilege.PRODUCT_ADMIN
				: UserPrivilege.NON_ADMIN;
}
