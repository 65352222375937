// This is another layer on top of the AFE ManualRulesContainer.
// It is necessary that this container wraps the entire "automation" component and not nested. Else the user input form will not render correctly.
// This container provides an automation context to all children components through the useAutomationMenu hook.
import React, { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import type { Ari } from '@atlassian/ari';
import { ConfluenceSiteAri } from '@atlassian/ari';
import { ManualRulesContainer } from '@atlassian/automation-manual-triggers';

import { ErrorBoundary, Attribution } from '@confluence/error-boundary';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSessionData } from '@confluence/session-data';

import type { ManualRulesData, MenuContext } from '../types';
import { getBaseAutomationUrl } from '../utils/getBaseAutomationUrl';
import { mapEnv } from '../utils/mapEnv';

type ConfluenceManualRulesContainerProps = {
	aris: Ari[];
	analyticsSource: string;
	spaceKey: string;
	children: () => React.ReactElement;
	onRuleInvocationLifecycleDone?: () => void;
	singleSpace: boolean;
};

type AutomationProviderProps = {
	children: ReactNode;
	value: MenuContext;
};
const AutomationMenuContext = createContext<MenuContext | undefined>(undefined);

const AutomationMenuProvider = ({ children, value }: AutomationProviderProps) => {
	return <AutomationMenuContext.Provider value={value}>{children}</AutomationMenuContext.Provider>;
};

// Hook that can be used anywhere under the ConfluenceManualRulesContainer to access the AutomationMenuContext values
export const useAutomationMenu = () => {
	const context = useContext(AutomationMenuContext);
	if (context === undefined) {
		throw new Error('useAutomationMenu must be used within a AutomationMenuProvider');
	}
	return context;
};

const i18n = defineMessages({
	ruleInvocationSuccessTitle: {
		id: 'automation-menu.flag.invocation.success.title',
		defaultMessage: 'Your automation is in progress',
		description:
			'The title of the flag shown to the user when a manually triggered automation rule has successfully started.',
	},
	ruleInvocationFailureTitle: {
		id: 'automation-menu.flag.invocation.failure.title',
		defaultMessage: 'Oops we ran into a problem',
		description:
			'The title of the flag shown to the user when a manually triggered automation fails to start.',
	},
	ruleInvocationFailureDescription: {
		id: 'automation-menu.flag.invocation.failure.description',
		defaultMessage: 'There was a problem and we couldn’t run the automation',
		description:
			'The description of the flag shown to the user when a manually triggered automation fails to start.',
	},
});

export const ConfluenceManualRulesContainer = withFlags(
	({
		flags,
		analyticsSource,
		aris,
		spaceKey,
		children,
		onRuleInvocationLifecycleDone,
		singleSpace,
	}: WithFlagsProps & ConfluenceManualRulesContainerProps) => {
		const { formatMessage } = useIntl();

		const { cloudId, environment, featureFlagClient } = useSessionData();

		const devHelperFFEnabled = featureFlagClient.getBooleanValue(
			'confluence.frontend.platform.automation.dev-helper',
			{ default: false },
		);
		const mappedEnvironment = mapEnv(environment, devHelperFFEnabled);

		return (
			<ManualRulesContainer
				env={mappedEnvironment}
				site={ConfluenceSiteAri.create({ siteId: cloudId }).toString()}
				query={{
					//if all pages are in same space, we only need one ari
					objects: singleSpace ? aris.slice(0, 1).map(String) : aris.map(String),
				}}
				onRuleInvocationLifecycleDone={onRuleInvocationLifecycleDone}
				onRuleInvocationSuccess={() => {
					void flags.hideFlag('automation-manual-trigger-execution-queued-flag').then(() =>
						flags.showInfoFlag({
							id: 'automation-manual-trigger-execution-finished-flag',
							title: formatMessage(i18n.ruleInvocationSuccessTitle),
							isAutoDismiss: true,
						}),
					);
				}}
				onRuleInvocationFailure={() => {
					void flags.hideFlag('automation-manual-trigger-execution-queued-flag').then(() =>
						flags.showErrorFlag({
							id: 'automation-manual-trigger-execution-finished-flag',
							title: formatMessage(i18n.ruleInvocationFailureTitle),
							description: formatMessage(i18n.ruleInvocationFailureDescription),
							isAutoDismiss: true,
						}),
					);
				}}
			>
				{(props: ManualRulesData) => {
					const baseAutomationUrl = getBaseAutomationUrl({ spaceKey });

					const contextValue: MenuContext = {
						...props,
						analyticsSource,
						aris,
						baseAutomationUrl,
						spaceKey,
					};

					return (
						<ErrorBoundary attribution={Attribution.AUTOMATION_FOR_CONFLUENCE}>
							<AutomationMenuProvider value={contextValue}>{children()}</AutomationMenuProvider>
						</ErrorBoundary>
					);
				}}
			</ManualRulesContainer>
		);
	},
);
